import { Reveal2LiteRiskScore } from '../../../api/models';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import Pager from '../Pager';

interface TableProps {
  data: Reveal2LiteRiskScore[];
}

const ViewIcon = styled.i`
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 5px;

  &.green {
    background-color: ${props => props.theme.colors.lowRisk};
  }
  &.yellow {
    background-color: ${props => props.theme.colors.mediumRisk};
  }
  &.red {
    background-color: ${props => props.theme.colors.highRisk};
  }
`;

const Table = styled.table`
  width: 100%;
  box-shadow: 0 0 15px ${props => props.theme.table.highlightColor};
  border-collapse: collapse;
  table-layout: fixed;

  th {
    font-size: 16px;
    color: ${props => props.theme.colors.shark};
    background-color: ${props => props.theme.colors.pacificBlue};
    padding: 12px;
    vertical-align: bottom;
    text-align: left;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${props => props.theme.table.rowColor};
  }
  &:hover {
    background-color: ${props => props.theme.table.highlightColor};
  }
`;

const TableItem = styled.td`
  padding: 12px;
  min-width: 54.5px;
  border: none !important;
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 16px;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const SelectLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  height: 30px;
  background-color: ${props => props.theme.colors.lightBlue};
  padding: 0 12px;
  vertical-align: middle;

  border-color: ${props => props.theme.colors.decemberDawn};
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const ItemSelect = styled.select`
  height: 30px;
  padding: 5px;

  border-color: ${props => props.theme.colors.decemberDawn};

  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
const createRows = (
  data: Reveal2LiteRiskScore[],
  page: number,
  itemsPerPage: number
) => {
  const startIndex = itemsPerPage * (page - 1);
  const endIndex = startIndex + itemsPerPage;
  let dataSubset: (Reveal2LiteRiskScore | boolean)[] = [];

  const romanNumerals = [
    '—',
    'WHO FC I',
    'WHO FC II',
    'WHO FC III',
    'WHO FC IV',
  ];

  if (startIndex > data.length) {
    return;
  } else {
    // the data listed is longer than the remaining data items
    if (endIndex > data.length) {
      dataSubset = [...data].reverse().slice(startIndex, data.length);
    } else {
      dataSubset = [...data].reverse().slice(startIndex, endIndex);
    }
  }
  return dataSubset.map((riskScore, i) => {
    if (typeof riskScore != 'boolean') {
      const whoValue = +(
        riskScore.referenceData.WhoFunctionalClass?.fields[0].value ?? 0
      );
      return (
        <TableRow key={i}>
          <TableItem>
            {format(parseISO(riskScore.dateTime), 'LLL d yyyy') ?? '—'}
          </TableItem>
          <TableItem>
            {riskScore.referenceData.BloodPressure
              ? `${riskScore.referenceData.BloodPressure?.fields[0].diastolic}/${riskScore.referenceData.BloodPressure?.fields[0].systolic}`
              : '—'}
          </TableItem>
          <TableItem>
            {riskScore.referenceData.HeartRate
              ? riskScore.referenceData.HeartRate?.fields[0].value
              : '—'}
          </TableItem>
          <TableItem>
            {riskScore.referenceData.SixMwd
              ? riskScore.referenceData.SixMwd?.sixMwd.value
              : '—'}
          </TableItem>
          <TableItem>
            {riskScore.referenceData.Bnp
              ? riskScore.referenceData.Bnp?.fields[0].value
              : '—'}
          </TableItem>
          <TableItem>
            {riskScore.referenceData.WhoFunctionalClass
              ? romanNumerals[whoValue ? whoValue : 0]
              : '—'}
          </TableItem>
          <TableItem>
            {riskScore.referenceData.Egfr
              ? riskScore.referenceData.Egfr?.fields[0].value
              : '—'}
          </TableItem>
          <TableItem>{riskScore.renalInsufficiency ? 'Yes' : '—'}</TableItem>
          <TableItem>
            <ViewIcon
              className={
                riskScore.score < 5
                  ? 'green'
                  : riskScore.score < 7
                    ? 'yellow'
                    : 'red'
              }
            />
            {riskScore.score}
          </TableItem>
        </TableRow>
      );
    } else {
      return (
        <TableRow key={i}>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
          <TableItem></TableItem>
        </TableRow>
      );
    }
  });
};

const RiskScoreTable = ({ data }: TableProps) => {
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(Math.max(Math.floor(data.length / itemsPerPage), 1));
  }, [itemsPerPage, data]);

  return (
    <>
      <Table data-testid='table'>
        <thead>
          <tr>
            <th>Date</th>
            <th>BP</th>
            <th>HR</th>
            <th>6MWD</th>
            <th>BNP/NT</th>
            <th>Who FC</th>
            <th>EGFR</th>
            <th>Renal Insufficiency</th>
            <th>Risk Score</th>
          </tr>
        </thead>
        <tbody data-testid='table-body'>
          {createRows(data, currentPage, itemsPerPage)}
        </tbody>
      </Table>
      <ControlsRow>
        <p>{`Results: ${Math.min(
          (currentPage - 1) * itemsPerPage + 1,
          data.length
        )} to ${Math.min(
          (currentPage - 1) * itemsPerPage + itemsPerPage,
          data.length
        )} of ${data.length}`}</p>
        <ControlsContainer>
          <SelectContainer>
            <SelectLabel htmlFor='limit-select'>Limit</SelectLabel>
            <ItemSelect
              id='limit-select'
              onChange={e => {
                setItemsPerPage(+e.currentTarget.value);
              }}>
              <option value={6}>6</option>
              <option value={12}>12</option>
              <option value={24}>24</option>
              <option value={48}>48</option>
            </ItemSelect>
          </SelectContainer>
          <Pager
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            onPageChange={setCurrentPage}
          />
        </ControlsContainer>
      </ControlsRow>
    </>
  );
};

export { RiskScoreTable };
