export const theme = {
  colors: {
    // Neutrals
    black: '#000',
    white: '#fff',
    solitude: '#eff1f5',
    echoBlue: '#acb5c7',
    charcoal: '#4a4a4a',
    eclipse: '#3e3e3e',
    concrete: '#f2f2f2',
    lightGrayishBlue: '#fafafc',
    lightGray: '#d3d3d3',
    stoneGray: '#9b9b9b',
    shark: '#212529',
    suvaGray: '#7e7d7e',
    slateGray: '#6e6e6e',
    ashGray: '#a9a9a9',
    deepGray: '#343a40',
    encouragementLight: '#888',
    encouragementDark: '#555',
    whisper: '#eee',
    raven: '#6c757d',
    lightBlue: '#e9ecef',
    // User Input
    mystic: '#e1e8ed',
    loblolly: '#b7c2c9',
    aliceBlue: '#f7f9fc',
    // Navigation
    pacificBlue: '#00a3e0',
    cerulean: '#0082b3',
    darkPacificBlue: '#0092c7',
    curiousBlue: '#407ec9',
    prussianBlue: '#003479',
    darkCerulean: '#184278',
    mangoTango: '#e57200',
    tawny: '#c26100',
    summerSky: '#3dbae8',
    pictonBlue: '#4b9dc2',
    // Bordering
    ghostWhite: '#f5f6fa',
    athensGray: '#e0e3eb',
    hawkesBlue: '#d1d6e1',
    borderColor: '#dddddd',
    decemberDawn: '#e0e3eb',
    // Adherence
    amaranth: '#d73c4c',
    amaranthDark: '#b00b12',
    texasRose: '#ffaf59',
    texasRoseDark: '#ff701b',
    mossGreen: '#aadea2',
    mossGreenDark: '#4cae6e',
    // Toastr Colors
    toastrError: '#bd362f',
    // Expired
    vividRed: '#d0021b',
    // Button Shadow
    shadow: `rgba(0,0,0,.175)`,
    //Profile
    amber: '#faaa55',
    //Education
    ceruleanBlue: '#5bc0de',
    //Risk Score
    lowRisk: '#8bce52',
    mediumRisk: '#e8cd5b',
    highRisk: '#c94d4d',
  },
  highcharts: {
    axisLabel: '#666666',
    legendLabel: '#333333',
    gridLine: '#e0e0e0',
    crosshair: 'rgba(204,214,235,0.25)',
    // highcharts v10 theme
    standard: [
      '#7cb5ec',
      '#434348',
      '#90ed7d',
      '#f7a35c',
      '#8085e9',
      '#f15c80',
      '#e4d354',
      '#2b908f',
      '#f45b5b',
      '#91e8e1',
    ],
    // min, max, common
    heatmap: [
      ['#cac2ea', '#7368a0', '#978dbf'],
      ['#e3b384', '#e5984b', '#f0af6e'],
      ['#8de6f0', '#49d0df', '#67d4e0'],
      ['#eded85', '#cdcd3d', '#d4d461'],
      ['#f2b6d4', '#f47db9', '#f598c7'],
      ['#fb998c', '#f8280e', '#fa6e5c'],
    ],
  },
  table: {
    rowColor: '#f6f6f6',
    highlightColor: '#ececec',
  },
  // Define minimum dimensions which the layout will change,
  // adapting to different screen sizes, for use in media queries.
  breakPoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  // Define the maximum width of container for different screen sizes.
  containerMaxWidths: {
    sm: '540px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
    page: '1920',
  },
  modalWidths: {
    sm: '300px',
    md: '480px',
    lg: '650px',
    xl: '800px',
    xxl: '900px',
  },
  iconButtonSizes: {
    sm: '20px',
    md: '30px',
    lg: '45px',
    xl: '60px',
  },
  modalPositions: {
    top: '-70%',
    center: '0%',
  },
};

export type ThemeType = typeof theme;

export default theme;
